'use strict';

angular.module('cpformplastApp.orders')
  .controller('OrderCreationController', function($scope, Modal, DataManager, $state, Notification) {

    $scope.jobs = [];
    $scope.order = {
      'details': '',
      'suborders': []
    };
    $scope.submitted = false;
    $scope.orderSubmitted = false;

    // Merchandise selection
    $scope.minDate = new Date();
    $scope.suborder = {};

    $scope.init = function() {

      DataManager.fetchJobs().then((data) => {
        $scope.jobs = data;
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un probème est survenu lors du chargement des jobs');
      });

      if ($state.params.orderId) {
        DataManager.fetchOrder($state.params.orderId).then(function(order){
          $scope.order = order;
          for (var i=0 ; i<$scope.order.suborders.length ; i++) {
            $scope.order.suborders[i].deliveryDate = new Date($scope.order.suborders[i].deliveryDate);
          }
        },function(error){
          console.log(error);
        });
      }
    };

    $scope.getSubordersTotal = function() {
      var total = 0;
      var subtotal = 0;
      for (var i=0 ; i<$scope.order.suborders.length ; i++) {
        if ($scope.order.suborders[i]._id) {
          total += $scope.order.suborders[i].quantity;
        }
        if (!$scope.order.suborders[i].toDelete) {
          subtotal += $scope.order.suborders[i].quantity;
        }
      }
      return Number(subtotal).toLocaleString() + '/' + Number(total).toLocaleString() + ' lbs';
    };

    $scope.submitSuborder = function(form) {
      $scope.orderSubmitted = true;
      if (!form.$valid || !$scope.selectedJob._id) {
        return false;
      }

      $scope.suborder.job = $scope.selectedJob;
      $scope.order.suborders.push({
        'job': $scope.suborder.job,
        'jobId': $scope.suborder.job._id,
        'quantity': $scope.suborder.quantity,
        'deliveryDate': $scope.suborder.deliveryDate
      });
      $scope.orderSubmitted = false;
      $scope.suborder = {};
      $scope.selectedJob = undefined;
    };

    $scope.submitOrder = function(form, create) {

      $scope.submitted = true;

      if (!form.$valid) {
        return false;
      }

      if ($scope.order.suborders <= 0) {
        Notification.error('Il faut associé au moins une marchandise à la commande');
        return false;
      }

      for (var i=0 ; i<$scope.order.suborders.length ; i++) {
        if(!$scope.order.suborders[i].quantity) {
          Notification.error('Veuillez specifier les quantités!');
          return false;
        }
      }

      var promise ;
      if (!$scope.order._id) {
        promise = DataManager.createOrder($scope.order);
      } else {
        promise = DataManager.updateOrder($scope.order);
      }

      promise.then((data) => {
        $scope.order._id = data._id;
        $state.params.orderId = data._id;

        for (var i=0 ; i<$scope.order.suborders.length ; i++) {
          $scope.order.suborders[i].orderId = $scope.order._id;
          $scope.saveSuborder($scope.order.suborders[i], create);
        }
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu');
      });

    };

    $scope.saveSuborder = function(suborder, create) {
      suborder.saved = false;
      suborder.deleted = false;

      var promise ;
      if (!suborder._id && !suborder.toDelete) {
        promise = DataManager.createSuborder(suborder);
      } else if (suborder._id && !suborder.toDelete) {
        promise = DataManager.updateSuborder(suborder);
      } else if (suborder._id && suborder.toDelete) {
        promise = DataManager.deleteSuborder(suborder);
      } else {
        suborder.deleted = true;
        $scope.detectSaveCompletion(create);
        return;
      }

      promise.then((data) => {
        if (data) {
          suborder._id = data._id;
          suborder.saved = true;
        } else {
          suborder.deleted = true;
        }
        $scope.detectSaveCompletion(create);
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu');
      });

    };

    $scope.detectSaveCompletion = function(create) {
      var updatedSuborders = [];
      for (var i=0 ; i<$scope.order.suborders.length ; i++) {
        if (!$scope.order.suborders[i].saved && !$scope.order.suborders[i].deleted) {
          return false;
        }

        if (!$scope.order.suborders[i].deleted) {
          updatedSuborders.push($scope.order.suborders[i]);
        }
      }
      Notification.success('La commande <strong>' + $scope.order.po + '</strong> fut enregistrée');
      if(create){
        $state.go('orders/order-management/order-creation', {'orderId': ''}, {reload:true});
      } else {
        $state.go('orders/order-management/order-creation', {'orderId' : $scope.order._id}, {reload:true});
      }
    };

    $scope.delete = Modal.confirm.delete(res => {
      $scope.deleteOrder();
    });

    $scope.deleteOrder = function() {
      DataManager.deleteOrder($scope.order).then((data) => {
        Notification.success("La commande <strong>" + $scope.order.po + "</strong> fut supprimée avec succès");
        $state.go('orders/order-management');
      })
      .catch(err => {
        console.log(err);
        Notification.error("La commande n'a pas pu être supprimé");
      });
    };

    // pour avertir que l'usager est en train de sortir sans avoir sauvegarder les information entrées\
    $scope.$on('$stateChangeStart',
      function(event, next, current){
        if ($scope.form.$dirty && !$scope.submitted) {
          if(!confirm("Souhaitez vous quitter cette page? Vous perderez alors tout contenue non sauvegarder.")) {
           event.preventDefault();
          }
        }
      });

    $scope.init();
});
